html {
  /* background-image: linear-gradient(
      rgba(18, 68, 160, 0.2),
      rgba(18, 68, 160, 0.2)
    ),
    url('../../assets/images/uplift_background.webp'); */
  background-image: url('../../assets/images/uplift_background.webp');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  top: 0;
  background-position-x: 80%;
  height: 100vh;
}

/* .iframe_gt {
    height: calc(100vh - 56px);
    width: 105vw;
} */
